import React, { Component } from 'react'
import Item from './Item'

export class Items extends Component {
  async componentDidMount() { console.log(`Items ${this.props.timezone}`) }
  render() {
    return (
      <div className='itemslist'>
        {this.props.items.map(el => (
            <Item key={el.id} item={el} selectedDate={this.props.selectedDate} timezone={this.props.timezone}/>
        ))}
      </div>
    )
  }
}

export default Items
