import React, { useState } from "react";
import { Link } from "react-router-dom";
import footertelegram from "../image/socialmediafooter.png";
import footerinst from "../image/socialmediafooterINST.png";
import footervk from "../image/socialmediafooterVK.png";
import AddClubForm from "./AddClubForm";
import QuestionForm from "./QuestionForm";

export default function Footer() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showQuestionForm, setShowQuestionForm] = useState(false);

  const handleAddFieldClick = () => {
    setShowOverlay(true);
    openOverlay();
  };

  const handleQuestionClick = () => {
    setShowQuestionForm(true);
    openOverlay();
  };

  const openOverlay = () => {
    const overlay = document.getElementById("overlay");
    overlay.style.display = "block";
    document.body.style.overflow = "hidden";
    overlay.classList.add("bluroverlay");
    overlay.addEventListener("click", closeOverlay);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setShowQuestionForm(false);
    const overlay = document.getElementById("overlay");
    overlay.style.display = "none";
    document.body.style.overflow = "auto";
    overlay.classList.remove("bluroverlay");
    overlay.removeEventListener("click", closeOverlay);
  };

  return (
    <>
      <footer>
        <div className="footerWrapper">
          <div className="footernavigation">
            <div className="footercollumn">
              <div className="footerTitle">Виды спорта</div>
              <div className="footerlinks">
                <Link to="/all-places/football">
                  <a href="">Футбол</a>
                </Link>
                <Link to="/all-places/tennis">
                  <a href="">Теннис</a>
                </Link>
                <Link to="/all-places/volleyball">
                  <a href="">Волейбол</a>
                </Link>
                <Link to="/all-places/basketball">
                  <a href="">Баскетбол</a>
                </Link>
              </div>
            </div>
            <div className="footercollumn">
              <div className="footerTitle">Информация</div>
              <div className="footerlinks">
                <a href="">Договор оферты</a>
                <div
                  href="#"
                  id="footer_addfield"
                  onClick={handleAddFieldClick}
                >
                  Добавить площадку
                </div>
                <a href="/admin">Панель администратора</a>
                <div
                  href="#"
                  id="footer_question"
                  onClick={handleQuestionClick}
                >
                  Остались вопросы
                </div>
              </div>
            </div>
            <div className="footercollumn new-string">
              <div className="footerTitle">
                Собрать команду или найти партнера по игре можно в нашей
                закрытой группе в Telegram
              </div>
              <div className="footerlinks">
                <a href="">
                  <img src={footertelegram} alt="" />
                </a>
              </div>
            </div>
            <div className="footercollumn fotcollast">
              <div className="footerTitle">Контакты</div>
              <div className="footerlinks footersociallinks">
                <a href="">
                  <img src={footervk} alt="" />
                </a>
                {/* <a href=""><img src={footerinst} alt="" /></a> */}
                <a href="">
                  <img src={footertelegram} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="footerline"></div>
          <div className="footerinfo">
            <b>Pricefit</b> — сервис онлайн-бронирования спортивных площадок. ©
            2023 Использование сайта означает согласие с{" "}
            <a href="#">
              <span className="footerinfogreen">
                Пользовательским соглашением
              </span>
            </a>
             и 
            <a href="#">
              <span className="footerinfogreen">
                Политикой обработки персональных данных
              </span>
            </a>
          </div>
        </div>
      </footer>
      {showOverlay && <AddClubForm onClose={closeOverlay} />}
      {showQuestionForm && <QuestionForm onClose={closeOverlay} />}
    </>
  );
}
