import React, { useState } from "react";
import "../styles/addclubform.css";

export default function QuestionForm({ onClose }) {
      const [formData, setFormData] = useState({
        name: "",
        tel: "",
        bookId: "",
        teleg: "",
        message: "",
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
        onClose(); // Закрыть форму после отправки (опционально)
      };
  return (
    <form className="form">
      <div className="questionForm addclubform">
        <button className="close" onClick={onClose}>
          x
        </button>
        <div className="question_header addform_header" id="di0">
          Задайте ваш вопрос
        </div>
        <div className="questionformContent addform_content">
          <input
            className="question_input addform_input"
            type="text"
            name="name"
            placeholder="Ваше имя"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            className="question_input addform_input"
            type="tel"
            name="tel"
            placeholder="Номер телефона"
            value={formData.tel}
            onChange={handleChange}
            maxLength="12"
            required
          />
          <input
            className="question_input addform_input"
            type="text"
            name="bookId"
            placeholder="Номер заявки"
            value={formData.bookId}
            onChange={handleChange}
          />
          <input
            className="question_input addform_input"
            type="text"
            name="teleg"
            placeholder="Ваш телеграм"
            value={formData.teleg}
            onChange={handleChange}
            required
          />
          <textarea
            className="question_input addform_input"
            type="text"
            name="message"
            placeholder="Ваш вопрос"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <button type="submit" className="send active">
            Отправить
          </button>
        </div>
      </div>
    </form>
  );
}
