// import {useEffect, useState} from 'react';
// const CarouselIndicators = ({ images, activeIndex, onClick }) => {
//     return (
//         <div className="carousel__indicators">
//             {images.map((_, index) => (
//                 <span
//                     key={index}
//                     className={`carousel__indicator ${index === activeIndex ? 'active' : ''
//                         }`}
//                     onClick={() => onClick(index)}
//                 />
//             ))}
//         </div>
//     );
// };
// const Carousel = ({ images, interval = 10000 }) => {
//     const [activeIndex, setActiveIndex] = useState(0);
//     const nextSlide = () => {
//         setActiveIndex((prevIndex) =>
//             prevIndex === images.length - 1 ? 0 : prevIndex + 1
//         );
//     };
//     const prevSlide = () => {
//         setActiveIndex((prevIndex) =>
//             prevIndex === 0 ? images.length - 1 : prevIndex - 1
//         );
//     };

//     const goToSlide = (index) => {
//         setActiveIndex(index);
//     };
//     return (
//         <div className="carousel">
//             <button onClick={prevSlide} className="carousel__btn carousel__btn--prev">
//                 &lt;
//             </button>
//             <img
//                 src={images[activeIndex]}
//                 alt={`Slide ${activeIndex}`}
//                 className="carousel__img"
//             />
//             <button onClick={nextSlide} className="carousel__btn carousel__btn--next">
//                 &gt;
//             </button>
//             <CarouselIndicators
//                 images={images}
//                 activeIndex={activeIndex}
//                 onClick={goToSlide}
//             />
//         </div>
//     );
// };
// export default Carousel;
import React, { Component, useEffect, useState, useRef, lazy } from 'react';
import { Slide } from "react-slideshow-image";
import { Suspense } from 'react';//back

// import slideImages from "";

import "react-slideshow-image/dist/styles.css?=2";
// import styles from "./Slider.module.css";

// function Slider(id, images) {
//   const img = images.split(';');
//   const Images =
//     img.map((photo) => (
//       '../img/placesImage/' + id + '/' + photo
//     ));
//   return (
//     <div className='container'>
//       <Slide easing="ease">
//         {Images.map((slide, index) => {
//           return (
//             <div className='slide' key={slide}>
//               <div style={{ backgroundImage: `url(${Images[index]})` }}>
//                 <span>Slide {index + 1}</span>
//               </div>
//             </div>
//           );
//         })}
//       </Slide>
//     </div>
//   );
// } export default { Slider };

const Slider = ({ id, images }) => {
  var Images = [];
  var ImagesDefault = ["https://icdn.lenta.ru/images/2024/03/18/12/20240318124428094/pic_8c33003cd43e3daceecec07346bda1f8.jpg"];
  if (!images) {
    Images = ["https://icdn.lenta.ru/images/2024/03/18/12/20240318124428094/pic_8c33003cd43e3daceecec07346bda1f8.jpg"];
  } else {
    const img = images.split(';');
    Images =
      img.map((photo) => (
        '../img/placesImage/' + id + '/' + photo + ''
      ));
  }
  console.log(Images);
  return (
    <div className='container'>
      <Slide easing="ease" autoplay={false} duration={3000}
        transitionDuration={500}
        indicators
        arrows>
        {Images.map((slide, index) => {
          return (
            <div className='slide' key={index}>
              <img className='slideimg' src={slide} alt='picture' />
            </div>
          );
        })}
      </Slide >
    </div >
  );
};
export default Slider;
