import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth';
import { AllPlaces } from "./components/all places/AllPlaces";
import { FieldPageConst } from './components/field page/FieldPageConst';
import { NotFound } from './components/NotFound';
import LogIn from "./components/admin/LogIn";
import { Admin } from './components/admin/Admin';
import { MainPage } from "./components/MainPage";
import ScrollToTop from "./components/ScrollToTop";
import TnxPage from "./components/field page/TnxPage";
import axios from 'axios';//back

function PrivateRoute({ children }) {
  const { auth, login } = useAuth();
  //console.log("privateroute:"); console.log(auth);
  return auth ? children : <Navigate to="/log-in" />;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: localStorage.getItem("selectedCity") || 1,
      cities: [],
      timezone: 4,
    };
    this.selectCity = this.selectCity.bind(this);
  }

  selectCity(city) {
    this.setState({
      city: city,
      timezone: this.state.cities.find(
        (item) => item["id"] == this.state.city
      )?.["timezone"],
    });
  }

  componentDidMount() {
    axios.get("https://api.pricefit.ru/api/getcities/all")
      .then((response) => {
        const timezoneTmp = response.data.find(
            (item) => item["id"] == this.state.city
          )?.["timezone"];
        this.setState({
          cities: response.data,
          timezone: timezoneTmp ? timezoneTmp : 4,
        });
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о городах:", error);
      });
  }

  render() {
    return (
      <div className="wrapper" id="wrapperMain">
        <div id="overlay"></div>
        <ScrollToTop />
        <AuthProvider>
          <Routes>
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <Header
                    showCity={true}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <MainPage />
                  <Footer />
                </>
              }
            />
            <Route
              path="/all-places"
              element={
                <>
                  <Header
                    hideButton={true}
                    showCity={false}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <AllPlaces
                    key={this.state.city}
                    city={this.state.city}
                    timezone={this.state.timezone}
                    typeSport={["undefined", "", false]}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path="/all-places/football"
              element={
                <>
                  <Header
                    hideButton={true}
                    showCity={false}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <AllPlaces
                    key={this.state.city}
                    city={this.state.city}
                    timezone={this.state.timezone}
                    typeSport={["type", "Футбол", true]}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path="/all-places/volleyball"
              element={
                <>
                  <Header
                    hideButton={true}
                    showCity={false}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <AllPlaces
                    key={this.state.city}
                    city={this.state.city}
                    timezone={this.state.timezone}
                    typeSport={["type", "Волейбол", true]}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path="/all-places/basketball"
              element={
                <>
                  <Header
                    hideButton={true}
                    showCity={false}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <AllPlaces
                    key={this.state.city}
                    city={this.state.city}
                    timezone={this.state.timezone}
                    typeSport={["type", "Баскетбол", true]}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path="/all-places/tennis"
              element={
                <>
                  <Header
                    hideButton={true}
                    showCity={false}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <AllPlaces
                    key={this.state.city}
                    city={this.state.city}
                    timezone={this.state.timezone}
                    typeSport={["type", "Теннис", true]}
                  />
                  <Footer />
                </>
              }
            />
            <Route
              path="/field-page/:id"
              element={
                <>
                  <Header
                    showCity={true}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <FieldPageConst />
                  <Footer />
                </>
              }
            />
            <Route
              path="/field-page/:id/:dateParent"
              element={
                <>
                  <Header
                    showCity={true}
                    selectCity={this.selectCity}
                    cities={this.state}
                  />
                  <FieldPageConst />
                  <Footer />
                </>
              }
            />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/tnank-page" element={<TnxPage />} />
          </Routes>
        </AuthProvider>
      </div>
    );
  }
}
export default App;
