import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FieldPageConst } from '../field page/FieldPageConst';
import { Widget } from '../Widget';
import { format } from 'date-fns';//back
export class Item extends Component {



  async componentDidMount() { console.log(`Item ${this.props.timezone}`)}

  constructor(props) {
    super(props);
    this.state = {
      link: this.props.selectedDate  ?
      //link: Array.isArray(this.props.selectedDate) && this.props.selectedDate.length === 0 ?
        `/field-page/` + this.props.item.id + '/' +this.props.selectedDate.at(1)
      :
      `/field-page/` + this.props.item.id,
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({
        link: this.props.selectedDate ? 
          `/field-page/` + this.props.item.id + '/' + this.props.selectedDate.at(1)
          :
          `/field-page/` + this.props.item.id
});
    }
  }

  render() {
    return (
      <>
        <Link to={this.state.link}>
          <div className='item'>
            <div className='imemcol1'>
              <img className='itemcover' src={"../img/" + this.props.item.image} alt='photoPlace' />
            </div>
            <div className='imemcol2'>
              <p>{this.props.item.name}</p>
              {/* <p className='widgetprice'>Свободные сеансы на {this.props.selectedDate ? format(this.props.selectedDate, 'dd.MM.yyyy') : format(new Date(), 'dd.MM.yyyy')}</p> */}
              <Widget idPlace={this.props.item.id} hideCalendar={true} selectedDate={this.props.selectedDate} timezone={this.props.timezone}/>
            </div>
            <div className='imemcol3'>
              <div className='itemminprice'>
                Стоимость часа от <br />
                <p>{this.props.item.min_price_per_hour}₽</p>
              </div>
              <div className='mainbutton'>Подробнее</div>
            </div>
          </div>
        </Link>
      </>
    )
  }
}

export default Item