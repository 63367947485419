import React from 'react'
import Items from "./Items";
import PlacesSearchFilter from "./PlacesSearchFilter"

import axios from 'axios';//back
import { Suspense, lazy } from 'react';//back
import headerlogo from '../../image/pricefit-logo.png'//back
import { format } from 'date-fns';//back


class AllPlaces extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allItems: [],
      items: [],

      uniqueDistrict: [],
      filterDistrict: [],

      uniqueTypePlace: [],
      filterTypePlace: [],

      selectedDate: [new Date().getDay(), format(new Date(), "dd.MM.yyyy")],

    };
  }

  async componentDidMount() {
    
    console.log(`AllP ${this.props.timezone}`)
    axios.get('https://api.pricefit.ru/api/city/' + this.props.city).then(response =>
      this.setState({
        allItems: response.data,
        items: response.data
      }, () => {
        //прогрузка фильтров района  response.data[0].district  item.district
        let districtAll = [], filterDistrictAll = [];
        response.data.map((item) => (districtAll.push(item.district)));
        let uniqueDistrictMap = districtAll.filter((item, index) => {
          return districtAll.indexOf(item) === index;
        });
        uniqueDistrictMap.map((i) => filterDistrictAll.push(false));

        let typePlaceAll = [], filterTypePlaceAll = [];
        response.data.map((item) => (typePlaceAll.push(item.typePlace)));
        let uniqueTypePlaceMap = typePlaceAll.filter((item, index) => {
          return typePlaceAll.indexOf(item) === index;
        });
        uniqueTypePlaceMap.map((i) => filterTypePlaceAll.push(false));
        this.setState({
          uniqueDistrict: uniqueDistrictMap, filterDistrict: filterDistrictAll,
          uniqueTypePlace: uniqueTypePlaceMap, filterTypePlace: filterTypePlaceAll
        }, () => { this.getDataFilter(this.props.typeSport) });
      }));
  }

  updateFilteredItems() {
    //typePlace
    let newItemsTypePlace = this.state.allItems.filter((i) => {
      let newListItemsBool = false;
      this.state.filterTypePlace.map((indexBool) => {
        newListItemsBool = newListItemsBool || indexBool;
      })
      newListItemsBool = !newListItemsBool;
      // console.log("Проверка, когда ни 1 район не выделен: " + newListItemsBool + "\n");

      this.state.filterTypePlace.map((filType, index) => {
        // console.log("Район: " + this.state.uniqueDistrict.at({index}) + " = " + {filDis} +"\n");
        if (filType) {
          newListItemsBool = newListItemsBool || (i.typePlace === this.state.uniqueTypePlace.at(index));
        }

      })
      return newListItemsBool;
    });
    //typePlace

    let newItemsTypeDistrict = newItemsTypePlace.filter((i) => {
      let newListItemsBool = false;
      this.state.filterDistrict.map((indexBool) => {
        newListItemsBool = newListItemsBool || indexBool;
      })
      newListItemsBool = !newListItemsBool;
      // console.log("Проверка, когда ни 1 район не выделен: " + newListItemsBool + "\n");

      this.state.filterDistrict.map((filDis, index) => {
        // console.log("Район: " + this.state.uniqueDistrict.at({index}) + " = " + {filDis} +"\n");
        if (filDis) {
          newListItemsBool = newListItemsBool || (i.district === this.state.uniqueDistrict.at(index));
        }

      })
      return newListItemsBool;
    });
    this.setState({ items: newItemsTypeDistrict });
  }

  getDateFilter = (date) => {
    console.log(`Дата была изменена на ${date}`);
    this.setState({ selectedDate: date }); //сюда прилетает [день недели, дата]
  }

  getDataFilter = (data) => {
    //alert(data[1] + " : " + data[2] + "\n");
    try {
      switch (data[0]) {
        case "type": {
          const newFilterTypePlace = this.state.filterTypePlace.map((item, index) => {
            if (index === this.state.uniqueTypePlace.indexOf(data[1])) {
              return data[2];
            } else {
              return item;
            }
          });
          this.setState({ filterTypePlace: newFilterTypePlace }, () => this.updateFilteredItems());
          break;
        }
        case "district": {
          const newFilterDistrict = this.state.filterDistrict.map((item, index) => {
            if (index === this.state.uniqueDistrict.indexOf(data[1])) {
              return data[2];
            } else {
              return item;
            }
          });
          this.setState({ filterDistrict: newFilterDistrict }, () => this.updateFilteredItems());
          break;
        }
      }
    } catch (e) {
      console.error('Error:');
    }
  }

  render() {
    return (
      <div className='main'>
        <PlacesSearchFilter getDataFilter={this.getDataFilter} getDateFilter={this.getDateFilter} districts={this.state.uniqueDistrict} typePlace={this.state.uniqueTypePlace} type={this.props.type} typeSport={this.props.typeSport} />
        <Suspense fallback={
          <picture><img className="headerlogo" src={headerlogo} alt="pricefitlogo" /></picture>
        }>
          <Items items={this.state.items} selectedDate={this.state.selectedDate} timezone={this.props.timezone}/>
        </Suspense>
      </div>
      // </div>
    )
  }
}
export { AllPlaces }