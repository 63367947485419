
import React from 'react';
import BookWidget from './BookWidget';
import Slider from './Slider';
import { Widget } from '../Widget';
import axios from 'axios';//back
import { Suspense, lazy } from 'react';//back
import headerlogo from '../../image/pricefit-logo.png'//back
import YandexMap from './YandexMap';

import { useEffect } from "react";
import { useLocation , Link} from "react-router-dom";

// const Widget = React.lazy(() => import('../Widget'));

class FieldPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            item: [],
            options: [],
            imageMap: [],
            imageForSlider: [],
            preparedVisit: [],
            otherPlaceData: []
        };
        this.parentFunction = this.parentFunction.bind(this);
    }

    parentFunction(preparedVisit) {
        this.setState({ preparedVisit: preparedVisit });
    }

    async componentDidMount() {
        axios.get('https://api.pricefit.ru/api/id/' + this.props.itemid).then(response => {
            this.setState({ item: response.data.at(0) }, () => {

                if (this.state.item.optionMap.includes(';')) {
                    this.setState({ options: this.state.item.optionMap.split(";") })
                }
                if (this.state.item.imageMap.includes(';')) {
                    this.setState({ imageMap: this.state.item.imageMap.split(";") })
                }
            });
            axios.get('https://api.pricefit.ru/api/idowner/' + response.data.at(0).idUser).then(response => {
                this.setState({ otherPlaceData: response.data });
            });
        });
    }

    render() {
        // const images =
        //     this.state.imageMap.map((photo) => (
        //         '../img/placesImage/' + this.state.item.id + '/' + photo
        //     ));
        //     console.log(images);

        // this.state.imageMap;
        const address = `${this.state.item.sity}, ${this.state.item.address}`;
        return (
            <>
                <div className='main' style={{ scrollBehavior: 'smooth' }}>
                    <div className='f_info'>
                        <div className='f_section'>
                            <div className='f_headersection'>
                                <Suspense fallback={<p>Loading</p>}>
                                    <div className='f_title'>
                                        {this.state.item.name}
                                        <span>{this.state.item.address}</span>
                                    </div>
                                </Suspense>
                                <div className='f_minprice'>
                                    от {this.state.item['min_price_per_hour']}₽/час
                                    <span>
                                        Минимальное время аренды:{" "}
                                        {this.state.item.standartInterval < 60
                                            ? `${this.state.item.standartInterval} минут`
                                            : `${Math.floor(this.state.item.standartInterval / 60)}:${String(
                                                this.state.item.standartInterval % 60
                                            ).padStart(2, "0")}`}
                                    </span>
                                </div>
                            </div>
                            <div className='f_tags'>
                                <span>
                                    <img src={"../../img/fieldpageicons/" + this.state.item.typePlace + ".png"} alt="Example" />
                                    {this.state.item.typePlace}
                                </span>
                            </div>
                            <div className='f_features'>
                                <div className='f_fifty'>
                                    <div className='f_feature'>
                                        <img src="../../img/fieldpageicons/coatingicon.png" alt="feateures" />
                                        <span>
                                            <b>Покрытие</b>
                                            <p>{this.state.options.at(0)}</p>
                                        </span>
                                    </div>
                                    <div className='f_feature'>
                                        <img src="../../img/fieldpageicons/typeicon.png" alt="feateures" />
                                        <span>
                                            <b>Тип площадки</b>
                                            <p>{this.state.options.at(1)}</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='f_fifty'>
                                    <div className='f_feature'>
                                        <img src="../../img/fieldpageicons/sizeicon.png" alt="feateures" />
                                        <span>
                                            <b>Длина х Ширина (м)</b>
                                            <p>{this.state.options.at(2)}</p>
                                        </span>
                                    </div>
                                    <div className='f_feature'>
                                        <img src="../../img/fieldpageicons/wtimeicon.png" alt="feateures" />
                                        <span>
                                            <b>Время работы</b>
                                            <p>{this.state.options.at(3)}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <Carousel images={images} "0.webp;1.webp;2.webp"/> */}
                            <Suspense fallback={<p>Loading</p>}>
                                {this.state.item.imageMap && this.state.item.imageMap.length > 0 ? (
                                    <Slider id={this.state.item.id} images={this.state.item.imageMap} />
                                ) : (
                                    <p>Загрузка изображений...</p>
                                )}
                            </Suspense>
                            <div className='f_blocktitle'>Оснащение:</div>
                            <div className='f_equipments'>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_park.png" alt="equipments" />
                                    <span>
                                        <b>Парковка</b>
                                        <p>{this.state.options.at(4) === "1" ? "Есть" : "Нет"}</p>
                                    </span>
                                </div>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_locker.png" alt="equipments" />
                                    <span>
                                        <b>Раздевалки</b>
                                        <p>{this.state.options.at(5) === "1" ? "Есть" : "Нет"}</p>
                                    </span>
                                </div>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_lighting.png" alt="equipments" />
                                    <span>
                                        <b>Освещение</b>
                                        <p>{this.state.options.at(6) === "1" ? "Есть" : "Нет"}</p>
                                    </span>
                                </div>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_shower.png" alt="equipments" />
                                    <span>
                                        <b>Душ</b>
                                        <p>{this.state.options.at(7) === "1" ? "Есть" : "Нет"}</p>
                                    </span>
                                </div>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_stands.png" alt="equipments" />
                                    <span>
                                        <b>Трибуны</b>
                                        <p>{this.state.options.at(8) === "1" ? "Есть" : "Нет"}</p>
                                    </span>
                                </div>
                                <div className='f_equipment'>
                                    <img src="../../img/fieldpageicons/eq_inventory.png" alt="equipments" />
                                    <span>
                                        <b>Инветарь</b>
                                        <p>{this.state.options.at(9)}</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='f_section' id='booking_widget'>
                            <div className='f_blocktitle'>Бронирование:</div>
                            {/*Виджет бронирование*/}
                            <Widget idPlace={this.props.itemid} callParentFunction={this.parentFunction} dateParent={this.props.dateParent}/>
                        </div>
                        <div className='f_section'>
                            <div className='f_blocktitle'>Местоположение:</div>
                            <YandexMap address={this.state.item.address} coords={this.state.item.coords} />
                        </div>
                        <div className='f_section'>
                            <div className='f_blocktitle'>Другие площадки клуба:</div>
                            <div className="f_otherplacelist">
                                <span>
                                    {/*сюда добавить */}
                                    {this.state.otherPlaceData.map(oPlace => (
                                        <Link to={`/field-page/` + oPlace.id}>
                                        <div className='f_otherplace'>
                                            <div className='f_othercover'>
                                                <img src="../img/placesImage/1/2.webp" alt="" width={200} height={150} />
                                            </div>
                                            <h1>{oPlace.name}</h1>
                                            <h2>{oPlace.min_price_per_hour}</h2>
                                        </div>
                                        </Link>
                                    ))}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='rightPanel'>
                        <BookWidget preparedVisit={this.state.preparedVisit} place={[this.state.item.name, this.state.item.address]}/>
                    </div>
                </div>

            </>
        )
    }
}
export { FieldPage }