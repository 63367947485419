import React, { useState } from 'react';
import { useAuth } from '../auth';
import headerlogo from '../../image/pricefit-logo.png'

//npm i crypto-js
import CryptoJS from 'crypto-js'

function myFunction() {
    var x = document.getElementById("pass");
    var eyeOpen = document.querySelector(".eyePassOpen");
    var eyeClose = document.querySelector(".eyePassClouse");
    if (x.type === "password") {
        x.type = "text";
        if (eyeOpen) eyeOpen.style.display = "block";
        if (eyeClose) eyeClose.style.display = "none";
    } else {
        x.type = "password";
        if (eyeOpen) eyeOpen.style.display = "none";
        if (eyeClose) eyeClose.style.display = "block";
    }
}
function LogIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        login(username, password);
    };
    return (
        <div className='loginMain'>
            <div className="screen-1">
                <div className="logo">
                    <img className="login_headerlogo" src={headerlogo} alt="pricefitlogo" />
                </div>
                <form onSubmit={handleSubmit} className='screen-2'>
                    <div className="email">
                        <label htmlFor="email" className='login_login'>Логин</label>
                        <div className="sec-2">
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99966 21C6.04548 21 3.18244 21 1.3237 18.4545C-0.535045 15.9091 6.04551 14 9.99969 14C13.9539 14 20.5354 15.9091 18.6762 18.4545C16.8169 20.9999 13.9538 21 9.99966 21Z" stroke="black" stroke-width="1.5" />
                                <circle cx="10" cy="6" r="5" stroke="black" stroke-width="1.5" />
                                <circle cx="10" cy="6" r="5" stroke="black" stroke-opacity="0.2" stroke-width="1.5" />
                            </svg>
                            <input type="text" name="email" className='login_input' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                    </div>
                    <div className="password">
                        <label htmlFor="password" className='login_login'>Пароль</label>
                        <div className="sec-2">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.55566 7.66667V5.44444C6.55566 2.98984 8.54551 1 11.0001 1V1C13.4547 1 15.4446 2.98985 15.4446 5.44444V7.66667" stroke="black" stroke-width="1.2" stroke-linecap="round" />
                                <rect x="1" y="7.66663" width="20" height="13.3333" rx="2" stroke="black" stroke-width="1.5" />
                                <circle cx="10.9998" cy="13.2222" r="1.47975" fill="black" />
                                <path d="M11 14.3334V16.5556" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            <div className="sec-3">
                                <input autocomplete="on" type="password" id="pass" name="password" className='login_input' onChange={(e) => setPassword(CryptoJS.SHA256(e.target.value).toString())} />
                                <svg onClick={myFunction} className="eyePassClouse" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_300_28)">
                                        <path d="M3.44287 1L17.4429 15" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.83258 5.45037C7.79322 5.411 7.72917 5.41128 7.69134 5.45212C7.07166 6.12101 6.69287 7.01627 6.69287 8C6.69287 10.0711 8.3718 11.75 10.4429 11.75C11.4266 11.75 12.3219 11.3712 12.9908 10.7515C13.0316 10.7137 13.0319 10.6497 12.9925 10.6103L12.0726 9.69042C12.0341 9.65189 11.972 9.65148 11.9311 9.68754C11.5345 10.0376 11.0135 10.25 10.4429 10.25C9.20023 10.25 8.19287 9.24264 8.19287 8C8.19287 7.42939 8.40528 6.9084 8.75533 6.51177C8.79139 6.47091 8.79098 6.40877 8.75245 6.37024L7.83258 5.45037ZM12.7183 8.21474C12.6975 8.19402 12.6871 8.16526 12.6888 8.13601C12.6915 8.09102 12.6929 8.04567 12.6929 8C12.6929 6.75736 11.6855 5.75 10.4429 5.75C10.3972 5.75 10.3519 5.75136 10.3069 5.75404C10.2776 5.75579 10.2489 5.74532 10.2281 5.7246L9.13566 4.63213C9.08508 4.58155 9.10248 4.49592 9.16975 4.47164C9.56724 4.32819 9.99592 4.25 10.4429 4.25C12.5139 4.25 14.1929 5.92893 14.1929 8C14.1929 8.44695 14.1147 8.87563 13.9712 9.27312C13.947 9.3404 13.8613 9.35779 13.8107 9.30722L12.7183 8.21474Z" fill="black" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.05959 2.6772C5.02838 2.64599 4.98014 2.63902 4.94139 2.66017C2.71839 3.8737 1.22417 5.58892 0.454695 6.63052C-0.151566 7.45119 -0.151564 8.54881 0.454696 9.36948C1.74378 11.1145 5.06694 14.75 10.443 14.75C12.4843 14.75 14.2295 14.2259 15.6799 13.4802C15.7397 13.4494 15.7517 13.3694 15.7042 13.3218L14.7311 12.3487C14.7013 12.3189 14.656 12.311 14.6177 12.3287C13.4282 12.8802 12.0364 13.25 10.443 13.25C5.79209 13.25 2.85851 10.099 1.66118 8.47819C1.44627 8.18728 1.44627 7.81272 1.66118 7.52181C2.41659 6.49925 3.8631 4.86757 5.99598 3.8024C6.05758 3.77164 6.07173 3.68934 6.02305 3.64066L5.05959 2.6772ZM16.1237 11.62C16.0789 11.5752 16.0866 11.5007 16.1389 11.4652C17.5981 10.4762 18.6276 9.28676 19.2249 8.47819C19.4398 8.18728 19.4398 7.81272 19.2249 7.5218C18.0276 5.90104 15.094 2.75 10.443 2.75C9.45421 2.75 8.543 2.89244 7.7096 3.13232C7.67443 3.14244 7.63651 3.1328 7.61063 3.10692L6.57621 2.0725C6.52473 2.02102 6.54373 1.93362 6.61228 1.90911C7.7462 1.50365 9.02301 1.25 10.443 1.25C15.8192 1.25 19.1423 4.88555 20.4314 6.63052C21.0377 7.45119 21.0377 8.54881 20.4314 9.36948C19.8151 10.2038 18.7338 11.4702 17.1855 12.5648C17.146 12.5928 17.0919 12.5881 17.0576 12.5539L16.1237 11.62Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_300_28">
                                            <rect width="21" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg onClick={myFunction} className="eyePassOpen" width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 13C5.98647 13 2.8581 9.60671 1.61489 7.92384C1.20431 7.36804 1.20431 6.63196 1.61489 6.07616C2.8581 4.39329 5.98647 1 11 1C16.0135 1 19.1419 4.39329 20.3851 6.07616C20.7957 6.63195 20.7957 7.36804 20.3851 7.92383C19.1419 9.60671 16.0135 13 11 13Z" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                    <circle cx="11" cy="7" r="3" stroke="black" stroke-width="1.5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <button className="login" type="submit">Войти</button>
                </form>
            </div>
        </div>
    )
}
export default LogIn