import React, { Component } from 'react';

class YandexMap extends Component {
  componentDidMount() {
    // Подключение скрипта API Яндекс.Карт
    const script = document.createElement('script');
    script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ca42e8dc-6ff9-44ea-b4ff-255e128e1084';
    script.type = 'text/javascript';
    script.onload = this.initMap;
    script.onload = this.initMap;
    script.onerror = () => {
      console.error('Ошибка загрузки скрипта API Яндекс.Карт');
    };
    document.head.appendChild(script);
  }

  initMap = () => {
    // Инициализация карты после загрузки API
    window.ymaps.ready(() => {
      this.map = new window.ymaps.Map('map', {
        center: [55.76, 37.64], // Координаты центра карты (Москва)
        zoom: 17,
        controls: []
      });
      // Отключение прокручивания карты при скролле
      this.map.behaviors.disable('scrollZoom');
      // Геокодирование адреса из пропсов
      //this.geocodeAddress(this.props.address);
      //const coords = localStorage.getItem('YandexMapFix_coords');
      const coords = this.props.coords.split(",");
      console.log("sliting coords" + this.props.coords.split(","));
      this.map.setCenter(coords);
      const placemark = new window.ymaps.Placemark(coords, {
        balloonContent: "address",
        preset: 'islands#icon',
        iconColor: 'red'
      });
      this.map.geoObjects.add(placemark);
      //this.map.geoObjects.add(localStorage.getItem('YandexMapFix_placemark'));
      //console.log("save yMap");
    });
  };

  geocodeAddress = (address) => {
    console.log(`Геокодирование адреса: ${address}`);
    window.ymaps.geocode(address).then((res) => {
      const firstGeoObject = res.geoObjects.get(0);
      if (firstGeoObject) {
        const coords = firstGeoObject.geometry.getCoordinates();
        console.log(`Координаты для адреса "${address}": ${coords}`);

        // Перемещаем центр карты на координаты адреса
        this.map.setCenter(coords);

        // Пример добавления метки по координатам адреса
        const placemark = new window.ymaps.Placemark(coords, {
          balloonContent: address,
          preset: 'islands#icon',
          iconColor: 'red'
        });
        // localStorage.setItem('YandexMapFix_coords', coords);
        // localStorage.setItem('YandexMapFix_placemark', placemark); console.log("save yMap");
        this.map.geoObjects.add(placemark); // Добавление метки на карту
      } else {
        console.error(`Геокодирование не вернуло результат для адреса: ${address}`);
      }
    }).catch((error) => {
      console.error('Ошибка геокодирования:', error);
    });
  };

  render() {
    return (
      <div>
        <div id="map" style={{ width: '100%', height: '400px' }}></div>
      </div>
    );
  }
}

export default YandexMap;