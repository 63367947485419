import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MpFootball from '../image/mainpage/mp_football.png'
import MpVoleyball from '../image/mainpage/mp_volleyball.png'
import MpTennis from '../image/mainpage/mp_tennis.png'
import MpBasketball from '../image/mainpage/mp_basketball.png'
import MpGuide1 from '../image/mainpage/mp_guide1.png'
import MpGuide2 from '../image/mainpage/mp_guide2.png'
import MpGuide3 from '../image/mainpage/mp_guide3.png'
import MpPlus1 from '../image/mainpage/mp_plus1.png'
import MpPlus2 from '../image/mainpage/mp_plus2.png'
import MpPlus3 from '../image/mainpage/mp_plus3.png'
const MainPage = () => {
    return (
        <>
            <section className='mp_start section' >
                <div className='mp_start_col'>
                    <b>
                        Pricefit - бронирование <br />
                        спортивных площадок в <br />
                        Саратове и Энгельсе
                    </b>
                    <div className='mp_start_descrip'>
                        Выберете и забронируйте площадку для любимого вида спорта в несколько кликов без комиссии и звонков
                    </div>
                    <Link to='/all-places'>
                        <button className='mp_start_mainbut'>
                            Выбрать площадку
                            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5576 0.25H0.682617C0.530273 0.25 0.398438 0.305664 0.287109 0.416992C0.175781 0.52832 0.120117 0.660156 0.120117 0.8125V13.1875C0.120117 13.3398 0.175781 13.4717 0.287109 13.583C0.398438 13.6943 0.530273 13.75 0.682617 13.75H17.5576C17.71 13.75 17.8418 13.6943 17.9531 13.583C18.0645 13.4717 18.1201 13.3398 18.1201 13.1875V0.8125C18.1201 0.660156 18.0645 0.52832 17.9531 0.416992C17.8418 0.305664 17.71 0.25 17.5576 0.25ZM16.9951 9.25H14.7451V4.75H16.9951V9.25ZM8.55762 8.58203C8.22949 8.46484 7.95996 8.2627 7.74902 7.97559C7.53809 7.68848 7.43262 7.36328 7.43262 7C7.43262 6.63672 7.53809 6.31152 7.74902 6.02441C7.95996 5.7373 8.22949 5.53516 8.55762 5.41797V8.58203ZM9.68262 5.41797C10.0107 5.53516 10.2803 5.7373 10.4912 6.02441C10.7021 6.31152 10.8076 6.63672 10.8076 7C10.8076 7.36328 10.7021 7.68848 10.4912 7.97559C10.2803 8.2627 10.0107 8.46484 9.68262 8.58203V5.41797ZM1.24512 4.75H3.49512V9.25H1.24512V4.75ZM1.24512 10.375H4.05762C4.20996 10.375 4.3418 10.3193 4.45312 10.208C4.56445 10.0967 4.62012 9.96484 4.62012 9.8125V4.1875C4.62012 4.03516 4.56445 3.90332 4.45312 3.79199C4.3418 3.68066 4.20996 3.625 4.05762 3.625H1.24512V1.375H8.55762V4.24023C7.91309 4.36914 7.37695 4.69141 6.94922 5.20703C6.52148 5.72266 6.30762 6.32031 6.30762 7C6.30762 7.67969 6.52148 8.27734 6.94922 8.79297C7.37695 9.30859 7.91309 9.63086 8.55762 9.75977V12.625H1.24512V10.375ZM9.68262 12.625V9.75977C10.3271 9.63086 10.8633 9.30859 11.291 8.79297C11.7188 8.27734 11.9326 7.67969 11.9326 7C11.9326 6.32031 11.7188 5.72266 11.291 5.20703C10.8633 4.69141 10.3271 4.36914 9.68262 4.24023V1.375H16.9951V3.625H14.1826C14.0303 3.625 13.8984 3.68066 13.7871 3.79199C13.6758 3.90332 13.6201 4.03516 13.6201 4.1875V9.8125C13.6201 9.96484 13.6758 10.0967 13.7871 10.208C13.8984 10.3193 14.0303 10.375 14.1826 10.375H16.9951V12.625H9.68262Z" fill="white" />
                            </svg>
                        </button>
                    </Link>
                </div>
            </section>
            <section className='mp_select section'>
                <div className='mp_title'>Выбирайте площадки для любимого вида спорта</div>
                <div className='mp_sportsgrid'>
                    <Link to='/all-places/football'>
                        <div className='mp_select_sport'>
                            <img src={MpFootball} alt="Футбол" />
                            <b>Футбол</b>
                            <div className='mp_sl_viewall'>Посмотерть все</div>
                        </div>
                    </Link>
                    <Link to='/all-places/volleyball'>
                        <div className='mp_select_sport'>
                            <img src={MpVoleyball} alt="Волейбол" />
                            <b>Волейбол</b>
                            <div className='mp_sl_viewall'>Посмотерть все</div>
                        </div>
                    </Link>
                    <Link to='/all-places/tennis'>
                        <div className='mp_select_sport'>
                            <img src={MpTennis} alt="Теннис" />
                            <b>Теннис</b>
                            <div className='mp_sl_viewall'>Посмотерть все</div>
                        </div>
                    </Link>
                    <Link to='/all-places/basketball'>
                        <div className='mp_select_sport'>
                            <img src={MpBasketball} alt="Баскетбол" />
                            <b>Баскетбол</b>
                            <div className='mp_sl_viewall'>Посмотерть все</div>
                        </div>
                    </Link>
                </div>
            </section>
            <section className='mp_guide section'>
                <div className='mp_title'>Легкий способ арендовать площадку в 3 шага:</div>
                <div className='mp_guidegrid'>
                    <div className='mp_guidecard'>
                        <img src={MpGuide1} alt="Найдите подходящую площадку" className='mp_editimage' />
                        <b>Найдите подходящую площадку</b>
                        <div>
                            С помощью удобных фильтров по местоположению, виду спорта, свободному времени и оснащению
                        </div>
                    </div>
                    <div className='mp_guidecard'>
                        <img src={MpGuide2} alt="Выберете дату и время аренды" className='mp_editimage' />
                        <b>Выберете дату и время аренды</b>
                        <div>
                            Не нужно звонить во все клубы — посмотрите расписание нужных площадок по дням недели и свободному времени
                        </div>
                    </div>
                    <div className='mp_guidecard'>
                        <img src={MpGuide3} alt="Оформите бронирование" className='mp_editimage' />
                        <b>Оформите бронирование</b>
                        <div>
                            Забронируйте выбранное время и ожидайте подтверждения брони от администратора клуба
                        </div>
                    </div>
                </div>
            </section>
            <section className='mp_pluses section'>
                <div className='mp_title'>Почему удобно арендовать площадки через Pricefit?</div>
                <div className='mp_guidegrid'>
                    <div className='mp_guidecard'>
                        <img src={MpPlus1} alt="Экономия времени" />
                        <b>Экономия времени</b>
                        <div>
                            Можете посмотреть какие площадки свободны в подходящий день и время, с помощью удобного онлайн-расписания
                        </div>
                    </div>
                    <div className='mp_guidecard'>
                        <img src={MpPlus2} alt="Бесплатное бронирование" />
                        <b>Бесплатное бронирование</b>
                        <div>
                            Вы оплачиваете только стоимость аренды площадки: онлайн или в самом клубе, без комиссии и наценок
                        </div>
                    </div>
                    <div className='mp_guidecard'>
                        <img src={MpPlus3} alt="Экономия денег" />
                        <b>Экономия денег</b>
                        <div>
                            В будние дни, утром и в обед клубы делают скидку на аренду, мы выделяем самое выгодное время для бронирования
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export { MainPage }
