// import React from 'react';
import React, { useState } from 'react';
import { format } from 'date-fns';//back
export default function PlacesSearchFilter({ getDataFilter, getDateFilter, districts, typePlace, typeSport }) {
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [dateNow, setDateNow] = useState(new Date());
  const [isTypeSport, setIsTypeSport] = useState(undefined);
  if (typeSport.at(0)[2] == true) {
    setIsTypeSport = typeSport.at(1);
  }
  function toggleFilter() {
    setIsFilterVisible(!isFilterVisible);
    if (isFilterVisible) {
      var overlay = document.getElementById("overlay");
      overlay.style.display = "block";
      document.body.style.overflow = "hidden";
      document.getElementById("overlay").classList.add('bluroverlay');
    } else {
      var overlay = document.getElementById("overlay");
      overlay.style.display = "none";
      document.body.style.overflow = "auto";
      document.getElementById("overlay").classList.remove('bluroverlay');
    }
  }

  return (
    <div className='filterpanel'>
      <div className={`placessearchfilter ${isFilterVisible ? 'active' : ''}`}>
        <div className='filterheader'>
          <p>Фильтры</p>
          <button onClick={toggleFilter}>x</button>
        </div>
        <div className='searchfilter'>
          <p>Дата бронирования</p>
          <input className='selectdate' type="date" name="calendar" min={format(new Date(), 'yyyy-MM-dd')} value={dateNow} onChange={(e) => {
            if (e.target.value) {
              getDateFilter([new Date(e.target.value).getDay(), format(new Date(e.target.value), "dd.MM.yyyy")]);
              setDateNow(format(new Date(e.target.value), 'yyyy-MM-dd'));
            } else {
              getDateFilter([new Date().getDay(), format(new Date(), "dd.MM.yyyy")]);
              setDateNow(format(new Date(), 'yyyy-MM-dd'));
            }
          }}
          />
        </div>
        <div className='searchfilter'>
          <p>Вид спорта</p>
          {typePlace.map(el => (
            <div key={el.id}>
              <label for="selectokct">
                {typeSport.at(1) == el ?
                  <><input defaultChecked={true} onChange={(e) => getDataFilter(["type", e.target.value, e.target.checked])} type="checkbox" value={el} className='filterchekbox' id="preChecked"/>{el}</> :
                  <><input onChange={(e) => getDataFilter(["type", e.target.value, e.target.checked])} type="checkbox" value={el} className='filterchekbox' />{el}</>
                }
              </label>
              <br />
            </div>
          ))}
          
        </div>
        
        <div className='searchfilter'>
          <p>Расположение</p>
          {districts.map(el => (
            <div key={el.id}>
              <label for="selectokct">
                <input onChange={(e) => getDataFilter(["district", e.target.value, e.target.checked])} type="checkbox" value={el} className='filterchekbox' />
                {el}
              </label>
              <br />
            </div>
          ))}
        </div>
      </div>
      <button className="filter-toggle-button" onClick={toggleFilter}></button>
    </div>
  )
}
