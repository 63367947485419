import React from "react";
import axios from "axios"; //back
import { format, addDays, isValid, parse} from "date-fns"; //back
import { ru } from "date-fns/locale"; //back

const today = new Date();

const calendarForWidget = [
  [
    today.getDay(),
    "Сегодня",
    getFrontDay(today.getDay()),
    format(today, "dd.MM.yyyy"),
  ],
  [
    addDays(today, 1).getDay(),
    "Завтра",
    getFrontDay(addDays(today, 1).getDay()),
    format(addDays(today, 1), "dd.MM.yyyy"),
  ],
  [
    addDays(today, 2).getDay(),
    format(addDays(today, 2), "dd", { locale: ru }),
    getFrontDay(addDays(today, 2).getDay()),
    format(addDays(today, 2), "dd.MM.yyyy"),
  ],
  [
    addDays(today, 3).getDay(),
    format(addDays(today, 3), "dd", { locale: ru }),
    getFrontDay(addDays(today, 3).getDay()),
    format(addDays(today, 3), "dd.MM.yyyy"),
  ],
  [
    addDays(today, 4).getDay(),
    format(addDays(today, 4), "dd", { locale: ru }),
    getFrontDay(addDays(today, 4).getDay()),
    format(addDays(today, 4), "dd.MM.yyyy"),
  ],
  [
    addDays(today, 5).getDay(),
    format(addDays(today, 5), "dd", { locale: ru }),
    getFrontDay(addDays(today, 5).getDay()),
    format(addDays(today, 5), "dd.MM.yyyy"),
  ],
];
function getFrontDay(day) {
  switch (day) {
    case 0: {
      return "Вс";
    }
    case 1: {
      return "Пн";
    }
    case 2: {
      return "Вт";
    }
    case 3: {
      return "Ср";
    }
    case 4: {
      return "Чт";
    }
    case 5: {
      return "Пт";
    }
    case 6: {
      return "Сб";
    }
    default: {
      return "Error day";
    }
  }
}
function getBackDay(day) {
  switch (day) {
    case 0: {
      return "sunday";
    }
    case 1: {
      return "monday";
    }
    case 2: {
      return "tuesday";
    }
    case 3: {
      return "wednesday";
    }
    case 4: {
      return "thursday";
    }
    case 5: {
      return "friday";
    }
    case 6: {
      return "saturday";
    }
    default: {
      return "Error day";
    }
  }
}

class Widget extends React.Component {
  //this.props. selectedDate hideCalendar idPlace timezone dateParent
  constructor(props) {
    super(props);
    this.state = {
      place: [],
      cells: [], //id time price status (open close select) + interval
      selectedCells: [],
      //dateNow: "",
      preparedVisit: [],
      //selectedDate: this.props.selectedDate ? this.props.selectedDate : [new Date().getDay(), format(new Date(), "dd.MM.yyyy")],
      selectedDate: this.props.dateParent && isValid(parse(this.props.dateParent, "dd.MM.yyyy", new Date())) 
      ? [parse(this.props.dateParent, "dd.MM.yyyy", new Date()).getDay(), this.props.dateParent] 
      : [new Date().getDay(), format(new Date(), "dd.MM.yyyy")],
      autoUpdateData: [], //  undefined|true, day, data
    };
  }

  getFrontTime = (time) => {
    // так же сюда можно добавить проверку ебанного времени
    let hour = 0;
    let min = time;
    while (min >= 60) {
      hour++;
      min -= 60;
    }
    if (hour >= 24) {
      hour -= 24;
    }
    if (min < 10) {
      min = "0" + min;
    }
    return hour + ":" + min;
  };

  updateCells = () => {
    console.log(`updateCells ${this.state.selectedDate}`);
    const now = new Date();
    let timezoneOffset = this.props.timezone * 60;
    timezoneOffset = timezoneOffset ? timezoneOffset : 4 * 60;
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;
    const timeInTimezone = new Date(utcTime + timezoneOffset * 60000);
    const currentTime = timeInTimezone.getHours() * 60 + timeInTimezone.getMinutes();
    console.log(`updateCells() ${currentTime};`);
    axios.get("https://api.pricefit.ru/api/getdatacell/" +
      this.props.idPlace + "/" +
      getBackDay(this.state.selectedDate.at(0)) + "/" +
      this.state.selectedDate.at(1))
      .then((response) => {
        let data = response.data;
        console.log(data);
        if (this.state.selectedDate.at(1) === format(new Date(), "dd.MM.yyyy")){
          data = data.filter((item) => { 
            return item['finish'] >= currentTime;
          })
        } else {

        }
        this.setState({ cells: data }) 
      }
      );
  }

  async componentDidMount() {
    console.log(`componentDidMount(); props date ${this.props.dateParent}`);
    const dateTmp = this.props.dateParent;
    try {
      if (dateTmp) {
        // Проверяем, валидна ли дата
        if (!isValid(parse(dateTmp, "dd.MM.yyyy", new Date()))) {
          //dateTmp = new Date();
          throw new Error(`Неверная дата: ${dateTmp}`);
        }
        const findedIndexInCalendar = calendarForWidget.findIndex(item => item[3] == dateTmp);
        findedIndexInCalendar == -1 || findedIndexInCalendar > 5
          ? this.updateSelectedDate([parse(dateTmp, "dd.MM.yyyy", new Date()).getDay(), dateTmp], "otherDate")//indexInCalendar = "otherDate" 
          : this.updateSelectedDate([parse(dateTmp, "dd.MM.yyyy", new Date()).getDay(), dateTmp], findedIndexInCalendar);//indexInCalendar = findedIndexInCalendar;   
      } else {
        this.updateCells();
      }
    } catch (error) {
      console.error("Ошибка при обработке даты:", error.message);
      this.updateCells();
    }
    this.intervalId = setInterval(() => {
      console.log("Авто-обновление:", new Date().toLocaleTimeString());
      this.updateCells();
    }, 1000000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({ selectedDate: this.props.selectedDate }, () => {this.updateCells();});
    }
  }

  clearSelectedCells(){
    this.setState({selectedCells: []});
    const container4Cells = document.getElementById("containerForCells");
    const cellContainer = container4Cells.querySelectorAll('.w_price');
    cellContainer.forEach((element) => {
      element.classList.remove('w_price_select');
    });
  }

  updateSelectedCells(cell) {
    console.log("updateSelectedCells(cell);");
    const container4Cells = document.getElementById("containerForCells");
    const cellContainer = container4Cells.querySelectorAll('.w_price');

    let tmpCells = this.state.selectedCells;
    const targetElement = container4Cells.querySelector("#cell" + cell['id']);
    if (tmpCells.some((item) => item['id'] === cell['id'])) {
      tmpCells = tmpCells.filter((item) => item['id'] !== cell['id']);
      if (targetElement) targetElement.classList.remove("w_price_select");
    } else {
      tmpCells.push(cell);
      if (targetElement) targetElement.classList.add("w_price_select");
    }

    tmpCells = tmpCells.sort((a, b) => parseInt(a['id'].split('-')[1]) - parseInt(b['id'].split('-')[1]));
    const isInOrder = tmpCells.every((item, index) => {
      if (index === 0) return true; //Если это первый выбранный элемент, то его всегда можно выбрать
      return parseInt(item['id'].split('-')[1]) === parseInt(tmpCells[index - 1]['id'].split('-')[1]) + 1;
    });
    //если вернется false, то нужно сформировать новый массив с правильной сортировкой
    // функция сначала сортирует все ячейки, потом если выбранные ячейки стоят друг за другом, то она выдает true

    if (!isInOrder) {
      tmpCells = [];
      cellContainer.forEach((element) => {
        element.classList.remove('w_price_select');
      });
      tmpCells.push(cell);
    }
    console.log("Выделенные ячейки:" + tmpCells.length);
    console.log(tmpCells);
    this.setState({ selectedCells: tmpCells });

    //ТУТ ПРАВИТЬ АНИМАЦИЮ ВЫДЕЛЕНИЯ
    tmpCells.map((i) => {
      document.getElementById("cell" + i['id']).classList.add("w_price_select");
    });
    //ТУТ ПРАВИТЬ АНИМАЦИЮ ВЫДЕЛЕНИЯ

    if (Array.isArray(tmpCells) && tmpCells.length !== 0) {
      let mainTimeStart = tmpCells.at(0)['start'],
        mainTimeFinish = mainTimeStart,
        mainPrice = 0,
        mainData = this.state.selectedDate.at(1);
      for (let i = 0; i < tmpCells.length; i++) {
        mainTimeFinish = tmpCells.at(i)['finish'];
        mainPrice += tmpCells.at(i)['price'];
      }
      this.props.callParentFunction([
        mainData,
        mainTimeStart,
        mainTimeFinish,
        mainPrice,
        this.props.idPlace,
        "from widget with love",
      ]);
    } else {
      this.props.callParentFunction([
        '',
        '',
        '',
        '',
        this.props.idPlace,
        "from widget with love",
      ]);
    }
  }

  updateSelectedDate(selectdate, index){
    this.setState({ selectedDate: selectdate }, () => { this.updateCells() });
    this.clearSelectedCells();
    const container = document.getElementById("w_dates");
    container.querySelectorAll(".date_active").forEach((item) => {
      item.classList.remove("date_active"); // Удаляем старый класс
      item.classList.add("w_date");         // Добавляем новый класс
    });
    container.querySelector("#dateSelect" + index).classList.remove("w_date");
    container.querySelector("#dateSelect" + index).classList.add("date_active");
    if (index === "otherDate") {
      document.getElementById("otherDateInput").value = format(parse(selectdate[1], "dd.MM.yyyy", new Date()), "yyyy-MM-dd");
    }
  }

  render() {
    return (
      <div className="price_widget">
        {!this.props.hideCalendar && (
          <div className="w_dates" id="w_dates">
            {calendarForWidget.map((d, index) => (
              <label>
                <div
                  id={"dateSelect" + index}
                  className={index === 0 ? "date_active" : "w_date"}
                  onClick={() => {
                    this.updateSelectedDate([d.at(0), d.at(3)], index);
                  }}
                >
                  {d.at(1)}
                  <br />
                  <span>{d.at(2)}</span>
                </div>
              </label>
            ))}
            <label>
              <div id="dateSelectotherDate" className="w_date">
                Другая дата
                <br/>
                <input
                  className="selectdate"
                  type="date"
                  name="calendar"
                  min={format(new Date(), "yyyy-MM-dd")}
                  onChange={(e) => {
                    if (e.target.value) {
                      this.updateSelectedDate([new Date(e.target.value).getDay(), format(new Date(e.target.value), "dd.MM.yyyy")], "otherDate");
                    }
                  }}
                  id="otherDateInput"
                />
              </div>
            </label>
          </div>
        )}
        <div className="w_prices" id="containerForCells">
          {this.state.cells.length > 0 ? (
            this.state.cells.map((cell) => (
              <label
                key={cell['id']}
                onClick={() => {
                  if (!this.props.hideCalendar) {
                    if (cell['status'] !== "close") {
                      this.updateSelectedCells(cell);
                    }
                  }
                }}
              >
                <div
                  id={"cell" + cell['id']}
                  className={
                    cell['status'] === "close"
                      ? "w_price w_price_cheked"
                      : "w_price"
                  }
                >
                  {this.getFrontTime(cell['start'])}
                  <br />
                  <span>{cell['price']}₽</span>
                </div>
              </label>
            ))
          ) : (
            <div className="noCell">Нет доступных сеансов</div>
          )}
        </div>
      </div>
    );
  }
}
export { Widget };
